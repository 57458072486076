import React, { useState } from 'react';

const CONVERTKIT_FORM_ID = '7753448';
const CONVERTKIT_API_KEY = 'hrlHwRPE65AwRJj2dWxPLw';

const PasswordPage = () => {
    const [email, setEmail] = useState('');
    const [name, setName] = useState('');
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [error, setError] = useState('');

    const subscribeToConvertKit = async (email, firstName) => {
        try {
            const response = await fetch(`https://api.convertkit.com/v3/forms/${CONVERTKIT_FORM_ID}/subscribe`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json; charset=utf-8',
                },
                body: JSON.stringify({
                    api_key: CONVERTKIT_API_KEY,
                    email: email,
                    first_name: firstName
                })
            });

            const data = await response.json();

            // Check if we have a subscription object in the response
            if (data.subscription && data.subscription.id) {
                console.log('Subscription successful:', data);
                return data;
            } else {
                console.error('Subscription response error:', data);
                throw new Error(data.error || 'Subscription failed');
            }
        } catch (error) {
            console.error('Error subscribing to newsletter:', error);
            throw error;
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setError('');

        try {
            const result = await subscribeToConvertKit(email, name);
            if (result.subscription) {
                setIsSubmitted(true);
                console.log('Successfully subscribed with ID:', result.subscription.id);
            } else {
                setError('Unable to complete signup. Please try again.');
            }
        } catch (error) {
            console.error('Submission error:', error);
            setError(error.message || 'Something went wrong. Please try again.');
        }
    };

    return (
        <div
            className="min-h-screen flex flex-col relative"
            style={{ backgroundColor: '#4E8926', overflow: 'hidden' }}
        >
            <div className="text-black text-[12px] leading-[15px] font-newedge absolute md:left-[30px] md:top-[30px] w-full md:w-auto text-center md:text-left mt-4 md:mt-0">
                <div className="md:text-left">
                    LAUNCHING<br />
                    SPRING 2025
                </div>
            </div>

            <div className="hidden md:flex justify-center mt-[75px]">
                <img
                    src="/assets/images/logo_black.svg"
                    alt="SimSim Logo"
                    className="w-[242px] h-[75px]"
                />
            </div>

            <div className="flex-1 flex flex-col items-center px-4">
                <p className="text-[12px] uppercase text-black font-newedge mt-[120px] md:mt-[10vh] text-center">
                    Sign up to be the first to know<span className="md:hidden"><br /></span> when preorder is live
                </p>

                <p className="text-[24px] md:text-[32px] leading-[24px] md:leading-[32px] uppercase text-black font-newedge-rounded mt-[30px] text-center">
                    <span className="md:hidden">
                        This is more than a bag;<br />
                        it's a celebration of<br />
                        identity, culture, and the<br />
                        beauty of being distinct.<br />
                        Welcome to SIMSIM.
                    </span>
                    <span className="hidden md:inline">
                        This is more than a bag; it's a celebration of identity,<br />
                        culture, and the beauty of being distinct.<br />
                        Welcome to SIMSIM.
                    </span>
                </p>

                <div className="w-full max-w-md mt-[100px] md:mt-[12vh]">
                    <form onSubmit={handleSubmit} className="flex flex-col items-center">
                        {error && (
                            <div className="text-black/80 font-newedge text-[12px] mb-4 text-center">
                                {error}
                            </div>
                        )}
                        <div className="space-y-8 w-full">
                            <div>
                                <input
                                    type="text"
                                    value={name}
                                    onChange={(e) => setName(e.target.value)}
                                    placeholder="NAME*"
                                    required
                                    disabled={isSubmitted}
                                    className="w-full pb-2 text-[12px] uppercase font-newedge bg-transparent placeholder-black/50 text-black border-none outline-none appearance-none rounded-none"
                                    style={{
                                        WebkitAppearance: 'none',
                                        borderBottom: '1px solid black',
                                        borderRadius: 0,
                                    }}
                                />
                            </div>
                            <div>
                                <input
                                    type="email"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    placeholder="EMAIL*"
                                    required
                                    disabled={isSubmitted}
                                    className="w-full pb-2 text-[12px] uppercase font-newedge bg-transparent placeholder-black/50 text-black border-none outline-none appearance-none rounded-none"
                                    style={{
                                        WebkitAppearance: 'none',
                                        borderBottom: '1px solid black',
                                        borderRadius: 0,
                                    }}
                                />
                            </div>
                        </div>
                        <div className="mt-[20px] text-[12px] font-newedge">
                            {isSubmitted ? (
                                <div className="text-black/50">THANK YOU FOR SIGNING UP!</div>
                            ) : (
                                <button
                                    type="submit"
                                    className={`${name || email ? 'text-black' : 'text-black/50'}`}
                                >
                                    SUBMIT
                                </button>
                            )}
                        </div>
                    </form>
                </div>

                <div className="mt-[180px] md:mt-[15vh] flex justify-center">
                    <img
                        src="/assets/images/animation.gif"
                        alt="SimSim Logo Footer"
                        className="w-[140px] h-[64px] object-cover"
                    />
                </div>
            </div>

            <a
                href="https://www.instagram.com/simsimthelabel/"
                target="_blank"
                rel="noopener noreferrer"
                className="text-black text-[12px] leading-[15px] font-newedge absolute bottom-[30px] left-0 right-0 md:left-[30px] md:right-auto text-center md:text-left"
            >
                INSTAGRAM
            </a>
        </div>
    );
};

export default PasswordPage; 